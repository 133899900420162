<template>
  <div class="shai-box" v-if="ters">
    <img
      class="cha"
      @click="close"
      src="../assets/img/ding/icon-close.png"
      alt=""
    />
    <div class="box-main">
      <div class="main-nav">
        <h1>筛选</h1>
      </div>
      <!-- 创建时间 -->
      <div class="time-box">
        <el-form ref="form">
          <el-form-item label="创建时间">
            <el-col :span="11">
              <el-date-picker
                v-model="valueTime1"
                value-format="YYYY-MM-DD"
                type="daterange"
                @change="begin_time"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
            </el-col>
          </el-form-item>
        </el-form>
      </div>
      <!-- 合作单位 -->
      <div class="xuan" v-if="getismain">
        <el-form ref="form">
          <el-form-item label="合作单位">
            <el-col :span="11">
              <el-select
                filterable
                v-model="heZuoVal"
                placeholder="请选择合作单位"
              >
                <el-option
                  v-for="item in heZuoOptions"
                  :key="item.cid"
                  :value="item.cid"
                  :label="item.name"
                >
                  {{ item.name }}
                </el-option>
              </el-select>
            </el-col>
          </el-form-item>
        </el-form>
      </div>
      <!-- 服务项目 -->
      <div class="article">
        <el-form ref="form">
          <el-form-item label="服务项目">
            <el-col :span="12">
              <el-checkbox-group v-model="group" size="large">
                <el-checkbox-button
                  v-for="city in cities"
                  :key="city.id"
                  :label="city.id"
                >
                  {{ city.name }}
                </el-checkbox-button>
              </el-checkbox-group>
            </el-col>
          </el-form-item>
        </el-form>
      </div>
      <!-- 订单类型 -->
      <div class="xuan1">
        <el-form ref="form">
          <el-form-item label="订单类型">
            <el-col :span="11">
              <el-select
                filterable
                v-model="dinglei"
                placeholder="请选择订单类型"
              >
                <el-option
                  v-for="item1 in dingstyle"
                  :key="item1.value"
                  :value="item1.value"
                  :label="item1.label"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-form-item>
        </el-form>
      </div>

      <!-- 订单状态 -->
      <div class="xuan1">
        <el-form ref="form">
          <el-form-item label="订单状态">
            <el-col :span="11">
              <el-select
                filterable
                v-model="statusVal"
                placeholder="请选择订单状态"
              >
                <el-option
                  v-for="item1 in statusOptions"
                  :key="item1.id"
                  :value="item1.id"
                  :label="item1.name"
                >
                  {{ item1.name }}
                </el-option>
              </el-select>
            </el-col>
          </el-form-item>
        </el-form>
      </div>
      <!-- 技师 -->
      <div class="xuan1" v-if="getismain">
        <el-form ref="form">
          <el-form-item label="技师&#x3000;&#x3000;">
            <el-col :span="11">
              <el-select filterable v-model="tech" placeholder="请选择技师">
                <el-option
                  v-for="item1 in techlist"
                  :key="item1.uid"
                  :value="item1.uid"
                  :label="item1.nickname"
                >
                  {{ item1.nickname }}
                </el-option>
              </el-select>
            </el-col>
          </el-form-item>
        </el-form>
      </div>
      <!-- 客服 -->
      <div class="xuan1" v-if="getismain">
        <el-form ref="form">
          <el-form-item label="客服&#x3000;&#x3000;">
            <el-col :span="11">
              <el-select filterable v-model="kefu" placeholder="请选择客服">
                <el-option
                  v-for="item1 in kefulist"
                  :key="item1.uid"
                  :value="item1.uid"
                  :label="item1.nickname"
                >
                  {{ item1.nickname }}
                </el-option>
              </el-select>
            </el-col>
          </el-form-item>
        </el-form>
      </div>
       <div class="xuan1" v-if="getismain">
        <el-form ref="form">
          <el-form-item label="调度&#x3000;&#x3000;">
            <el-col :span="11">
              <el-select filterable v-model="diaodu" placeholder="请选择调度">
                <el-option
                  v-for="item1 in kefulist"
                  :key="item1.uid"
                  :value="item1.uid"
                  :label="item1.nickname"
                >
                  {{ item1.nickname }}
                </el-option>
              </el-select>
            </el-col>
          </el-form-item>
        </el-form>
      </div>
      <!-- 业务员 -->
      <div class="xuan1" v-if="getismain">
        <el-form ref="form">
          <el-form-item label="业务员&#x3000;">
            <el-col :span="11">
              <el-select
                filterable
                v-model="saleman"
                placeholder="请选择业务员"
              >
                <el-option
                  v-for="item1 in saleslist"
                  :key="item1.uid"
                  :value="item1.uid"
                  :label="item1.nickname"
                >
                  {{ item1.nickname }}
                </el-option>
              </el-select>
            </el-col>
          </el-form-item>
        </el-form>
      </div>
      <!-- 收费类型 -->
      <div class="type">
        <el-form ref="form">
          <el-form-item label="收费类型">
            <el-col :span="11">
              <el-radio-group v-model="typeVal">
                <el-radio :label="2">挂账</el-radio>
                <el-radio :label="1">现金</el-radio>
              </el-radio-group>
            </el-col>
          </el-form-item>
        </el-form>
      </div>
      <!-- 完成时间 -->
      <div class="time-box1">
        <el-form ref="form">
          <el-form-item label="完成时间">
            <el-col :span="11">
              <el-date-picker
                v-model="valueTime2"
                value-format="YYYY-MM-DD"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
            </el-col>
          </el-form-item>
        </el-form>
      </div>
      <!-- 订单审核 -->
      <div class="unit1" v-if="getismain">
        <el-form ref="form">
          <el-form-item label="订单审核">
            <el-col :span="11">
              <el-radio-group v-model="shenHeVal">
                <el-radio :label="1">等待审核</el-radio>
                <el-radio :label="2">初审未过</el-radio>
                <el-radio :label="3">终审未过</el-radio>
                <el-radio :label="4">审核通过</el-radio>
              </el-radio-group>
            </el-col>
          </el-form-item>
        </el-form>
      </div>
      <!-- 投诉 -->
      <div class="unit2" v-if="getismain">
        <el-form ref="form">
          <el-form-item label="投诉&#x3000;&#x3000;">
            <el-col :span="11">
              <el-radio-group v-model="touSuVal">
                <el-radio :label="2">是</el-radio>
                <el-radio :label="1">否</el-radio>
              </el-radio-group>
            </el-col>
          </el-form-item>
        </el-form>
      </div>
      <!-- 回访 -->
      <div class="unit3" v-if="getismain">
        <el-form ref="form">
          <el-form-item label="回访&#x3000;&#x3000;">
            <el-col :span="11">
              <el-radio-group v-model="huiFangVal">
                <el-radio :label="2">是</el-radio>
                <el-radio :label="1">否</el-radio>
              </el-radio-group>
            </el-col>
          </el-form-item>
        </el-form>
      </div>
      <div class="btn">
        <button class="set" @click="save">确定</button>
        <button class="cancel" @click="close">取消</button>
      </div>
    </div>
  </div>
</template>
<script>
import * as api from "../api/order";
import * as api1 from "../api/index";
import { reactive, ref } from "vue";
// const radio1 = ref("1");
// const radio2 = ref("1");
// const radio3 = ref("1");
// const radio4 = ref("1");

const cityOptions = [
  {
    id: 1,
    name: "非事故拖车",
  },
  {
    id: 2,
    name: "事故拖车",
  },
  {
    id: 3,
    name: "搭电",
  },
  {
    id: 4,
    name: "换胎",
  },
  {
    id: 5,
    name: "送水",
  },
  {
    id: 6,
    name: "送油",
  },
  {
    id: 7,
    name: "充气",
  },
  {
    id: 8,
    name: "快修",
  },
  {
    id: 9,
    name: "开锁",
  },
  {
    id: 10,
    name: "吊车",
  },
  {
    id: 11,
    name: "困境",
  },
  {
    id: 12,
    name: "地库",
  },
];
const dingstyle = [
  {
    value: 0,
    label: "全部订单",
  },
  {
    value: 1,
    label: "即时单",
  },
  {
    value: 2,
    label: "预约单",
  },
  {
    value: 3,
    label: "回程单",
  },
];
export default {
  props: ["ister", "message"],
  data() {
    return {
      ters: false,
      valueTime1: "",
      valueTime2: "",
      heZuoVal: "", // 合作
      heZuoOptions: [],
      statusVal: "", // 状态
      statusOptions: [],
      addressVal: "", // 事故点
      addressOptions: [],
      typeVal: "", // 类型
      shenHeVal: "", // 审核
      touSuVal: "", // 投诉
      huiFangVal: "", // 回访
      group: [],
      cities: cityOptions,
      newdatas: {},
      shaiList: [],
      page: 1,
      totalpage: 0,
      tech: "",
      techlist: [],
      kefu: "",
      diaodu:'',
      kefulist: [],
      saleman: "",
      saleslist: [],
      dingstyle,
      dinglei: "",
      datas: {
        page: 1,
        perpage: 8,
      },
      closelist: [],
    };
  },

  methods: {
    close() {
      console.log("点击关闭");
      this.ters = false;
      Object.assign(this.$data, this.$options.data());
      let data = {
        page: 1,
        perpage: 8,
      };
      this.newdatas = data;
      console.log(this.newdatas);
      this.$emit("datas", JSON.parse(JSON.stringify(this.newdatas)));
    },
    begin_time(e) {
      console.log("------", e);
    },
    // 点击确定筛选
    save() {
      let data = {
        page: 1,
        perpage: 8,
        createTimeBegin: this.valueTime1[0],
        createTimeEnd: this.valueTime1[1],
        srcCid: this.heZuoVal,
        rescueStatus: this.statusVal,
        chargeType: this.typeVal,
        finishTimeBegin: this.valueTime2[0],
        finishTimeEnd: this.valueTime2[1],
        reviewStatus: this.shenHeVal,
        returnStatus: this.huiFangVal,
        complainStatus: this.touSuVal,
        rescueType: this.group,
        keyword: "",
        tech: this.tech,
        saleman: this.saleman,
        kefu: this.kefu,
        diaodu:this.diaodu,
        rescueStyle: this.dinglei,
      };
      this.newdatas = data;
      console.log(this.newdatas);
      this.$emit("datas", JSON.parse(JSON.stringify(this.newdatas)));
      this.ters = false;
    },
  },
  computed: {
    getismain() {
      return this.$store.state.ismain;
    },
  },
  watch: {
    async ister(newval) {
      //console.log("----", newval);
      this.ters = !this.ters;

      let res = await api.getOrderListOp();
      //console.log(res);
      this.heZuoOptions = res.data.splist;
      this.statusOptions = res.data.orderstatus;

      let res1 = await api1.gettechlist();
      this.techlist = res1.data;

      let res2 = await api1.getsaleslista();
      this.saleslist = res2.data;

      let res3 = await api1.getkefulist();
      this.kefulist = res3.data;
    },
  },
};
</script>
<style lang="less" scoped>
.el-checkbox-group {
  width: 570px;
}
.shai-box {
  width: 760px;
  height: 920px;
  margin-top: 0px;
  float: right;
  position: absolute;
  top: -80px;
  right: 0;
  opacity: 1;
  z-index: 22;
}
img {
  float: left;
  width: 103px;
  height: 103px;
  margin-top: 36px;
  margin-left: -29px;
}
.box-main {
  width: 720px;
  height: 920px;
  background: #fff;
  margin-left: 40px;
  box-shadow: 0px 3px 20px rgba(179, 189, 195, 0.4);
  opacity: 1;
  border-radius: 0px;
  position: absolute;
  overflow-y: scroll;
  padding-left: 30px;
}
.box-main::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
.main-nav {
  width: 720px;
  height: 80px;
  background: #ffffff;
  opacity: 1;
  border-radius: 0px;
  // padding-left: 30px;
  // box-sizing: border-box;
}
h1 {
  height: 26px;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 80px;
  color: #2a3346;
  opacity: 1;
}
.time-box {
  width: 400px;
  height: 40px;
  opacity: 1;
  // margin-left: 30px;
  // margin-top: 30px;
}
.time-box1 {
  // float: left;
  width: 400px;
  height: 40px;
  // margin-left: 34px;
}
.xuan {
  // width: 400px;
  height: 40px;
  opacity: 1;
  // margin-left: 34px;
  margin-top: 12px;
}
.xuan1,
.xuan2 {
  // width: 400px;
  height: 40px;
  opacity: 1;
  // margin-left: 34px;
  margin-bottom: 6px;
}
.el-select--default {
  margin-right: 20px;
}
.el-col-2 {
  text-align: center;
}
.article {
  // margin-left: 34px;
  margin-top: 12px;
}
.article-tit {
  float: left;
  width: 56px;
  height: 19px;
  font-size: 14px;
  margin-right: 13px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 40px;
  color: #606266;
  opacity: 1;
}
.el-checkbox-button {
  margin-right: 10px;
  margin-bottom: 10px;
  border-left: 1px solid #dcdfe6;
}
.el-checkbox-button:first-child {
  border-left: 0;
}
.matter {
  float: left;
  width: 590px;
  height: 80px;
}
.unit1 {
  // width: 300px;
  // height: 19px;
  opacity: 1;
  // margin-left: 34px;
  margin-top: 20px;
}
.unit2,
.unit3 {
  opacity: 1;
  // margin-left: 34px;
}
.type {
  // float: left;
  // width: 56px;
  // height: 19px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 0px;
  color: #606266;
  opacity: 1;
  // margin: 20px 15px 0 5px;
  // margin-left: 34px;
}
.btn {
  width: 180px;
  height: 32px;
  opacity: 1;
  margin-left: 76px;
  margin-top: 44px;
  // background-color: mediumspringgreen;
}
.set {
  width: 80px;
  height: 32px;
  background: #2c68ff;
  opacity: 1;
  color: #fff;
  border: none;
  border-radius: 2px;
}
.cancel {
  width: 80px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #727e96;
  opacity: 1;
  margin-left: 20px;
  border-radius: 2px;
}
</style>
